import React, { useState, useEffect } from 'react';
import AdminLayout from '../../../components/admin-panel/AdminLayout';
import InputField from '../../../components/admin-panel/InputField';
import Button from '../../../components/admin-panel/Button';
import AdminColors from '../../../components/admin-panel/AdminColors';
import { useAuth } from '../../hooks/AuthContext';
import UploadFile from '../../../components/admin-panel/UploadFile';
import AdminTable from '../../../components/admin-panel/Table';
import Notification from '../../../components/admin-panel/Notification';

interface Brand {
  id: number;
  name: string;
}

const SellCar: React.FC = () => {
  const { user } = useAuth(); // Get logged-in admin details
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [carDetails, setCarDetails] = useState<any>(null);
  const [images, setImages] = useState<File[]>([]);
  const [isFeatured, setIsFeatured] = useState(false); 
  const [error, setError] = useState('');
  const [notification, setNotification] = useState({ show: false, message: '', type: '' as 'success' | 'error' });
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [selectedBrandId, setSelectedBrandId] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');

  const BACKEND_URL = 'https://eeqo.link';

  // Fetch all brands when component mounts
  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/api/brands/list`);
        if (response.ok) {
          const data = await response.json();
          // Now properly accessing the 'brands' array from the response
          setBrands(data.brands); // Assuming the JSON object has a property 'brands' which is an array
        } else {
          console.error('Failed to fetch brands');
          setBrands([]); // Fallback to an empty array on fetch failure
        }
      } catch (error) {
        console.error('Error fetching brands:', error);
        setBrands([]); // Set to an empty array in case of error
      }
    };

    fetchBrands();
  }, []);
  
  // Fetch car details from the DVLA API
  const fetchCarDetails = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setCarDetails(null); // Reset the previous car details
    setLoading(true); // Show loading state

    if (!registrationNumber) {
      setError('Please enter a registration number.');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${BACKEND_URL}/api/sell-cars/details`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ registrationNumber }),
      });

      if (response.ok) {
        const data = await response.json();
        setCarDetails(data.car); // Save car details for review
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to fetch car details.');
      }
    } catch (error) {
      console.error('Error fetching car details:', error);
      setError('Failed to fetch car details.');
    } finally {
      setLoading(false); // Hide loading state
    }
  };

  const handleFileUpload = (files: File[]) => {
    setImages(files);
  };

  // Submit car details to the database
  const submitCarDetails = async () => {
    if (!carDetails) return;

    const formData = new FormData();
    formData.append('registrationNumber', carDetails.registration_number);
    formData.append('make', carDetails.make || 'Unknown');
    formData.append('year', carDetails.year?.toString() || '0');
    formData.append('color', carDetails.color || 'Unknown');
    formData.append('description', carDetails.description || '');
    formData.append('submitted_by', user?.id?.toString() || '');
    formData.append('price', carDetails.price?.toString() || '');
    formData.append('mileage', carDetails.mileage?.toString() || '');
    formData.append('category', selectedCategory);
    formData.append('listed', carDetails.listed?.toString() || 'false');
    formData.append('is_featured', isFeatured.toString());
    formData.append('status', carDetails.status || 'unsold');
    formData.append('brand_id', selectedBrandId);

    images.forEach((image) => {
      formData.append('images', image); // Add multiple images
    });

    try {
      const response = await fetch(`${BACKEND_URL}/api/sell-cars/create`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        setNotification({ show: true, message: 'Car details submitted successfully.', type: 'success' });
        setCarDetails(null);
        setImages([]);
      } else {
        const errorData = await response.json();
        setNotification({ show: true, message: errorData.message || 'Failed to submit car details.', type: 'error' });
      }
    } catch (error) {
      console.error('Error submitting car details:', error);
      setNotification({ show: true, message: 'Failed to submit car details due to a network error.', type: 'error' });
    }
  };

  const tableColumns = [
    { key: 'field', header: 'Field' },
    { key: 'value', header: 'Value' }
  ];

  const carData = carDetails ? [
    { field: 'Registration Number', value: carDetails.registration_number },
    { field: 'Make', value: carDetails.make },
    { field: 'Year', value: carDetails.year },
    { field: 'Color', value: carDetails.color },
    { field: 'Fuel Type', value: carDetails.fuelType },
    { field: 'Tax Status', value: carDetails.taxStatus },
    { field: 'Cylinder Capacity', value: carDetails.engineCapacity },
    { field: 'CO2 Emissions', value: carDetails.co2Emissions ? `${carDetails.co2Emissions} g/km` : 'N/A' },
    { field: 'Euro Status', value: carDetails.euroStatus },
    { field: 'MOT Status', value: carDetails.motStatus },
    { field: 'MOT Expiry Date', value: carDetails.motExpiryDate },
    { field: 'Marked For Export', value: carDetails.markedForExport ? 'Yes' : 'No' },
    { field: 'Type Approval', value: carDetails.typeApproval },
    { field: 'Wheelplan', value: carDetails.wheelplan },
    { field: 'Revenue Weight', value: carDetails.revenueWeight ? `${carDetails.revenueWeight} kg` : 'N/A' },
    { field: 'Real Driving Emissions', value: carDetails.realDrivingEmissions },
    { field: 'Date of Last V5C Issued', value: carDetails.dateOfLastV5CIssued },
    { field: 'Automated Vehicle', value: carDetails.automatedVehicle ? 'Yes' : 'No' },
    { field: 'Submitting By', value: user?.name || 'Admin' },
] : [];

  const carCategories = [
    { label: "Sedan", value: "Sedan" },
    { label: "SUV", value: "SUV" },
    { label: "Hatchback", value: "Hatchback" },
    { label: "Coupe", value: "Coupe" },
    { label: "Convertible", value: "Convertible" },
    { label: "Pickup Truck", value: "Pickup Truck" },
    { label: "Minivan", value: "Minivan" },
  ];

   // Add Dropdown for Featured Status
   const featureOptions = [
    { label: "No", value: "false" },
    { label: "Yes", value: "true" }
  ];

  return (
    <AdminLayout>
      {notification.show && (
        <Notification message={notification.message} type={notification.type} />
      )}
      <div className="flex justify-center mt-4">
        <div className={`w-96 p-8 text-center ${AdminColors.secondary} border ${AdminColors.border}`}>
          <h1 className="text-xl font-bold mb-4">Sell Your Car</h1>
          <form onSubmit={fetchCarDetails}>
            <InputField
              type="text"
              name="registrationNumber"
              value={registrationNumber}
              placeholder="Enter Registration Number"
              onChange={(e) => setRegistrationNumber(e.target.value)}
              required
            />
            <Button type="submit" className="mt-4 w-full">
              {loading ? 'Fetching...' : 'Fetch Car Details'}
            </Button>
          </form>
          {error && <p className="text-red-500 mt-4">{error}</p>}
        </div>
      </div>

      <div className="container mx-auto px-4 py-8">
    {carDetails ? (
      <div className="flex flex-col md:flex-row gap-8">
        <div className="md:w-1/2 bg-slate-800 p-6 rounded-lg shadow">
          <h2 className="text-xl font-bold text-white mb-4">Review Car Details</h2>
          <AdminTable
            columns={tableColumns}
            data={carData}
            className="bg-slate-700 text-white"
          />
        </div>
        <div className="md:w-1/2 bg-slate-800 p-6 rounded-lg shadow">
          <h2 className="text-xl font-bold text-white mb-4">Add Car Details</h2>
          <InputField
            label="Price"
            type="number"
            name="price"
            value={carDetails.price || ''}
            placeholder="Enter price"
            onChange={(e) => setCarDetails({ ...carDetails, price: parseFloat(e.target.value) })}
            className="mb-4"
          />
          <InputField
            label="Mileage"
            type="number"
            name="mileage"
            value={carDetails.mileage || ''}
            placeholder="Enter mileage"
            onChange={(e) => setCarDetails({ ...carDetails, mileage: parseFloat(e.target.value) })}
            className="mb-4"
          />
          <InputField
            label="Description"
            type="textarea"
            name="description"
            value={carDetails.description || ''}
            placeholder="Enter description (optional)"
            onChange={(e) => setCarDetails({ ...carDetails, description: e.target.value })}
            className="mb-4"
          />
           <InputField
            label="Select Brand"
            type="select"
            name="brandId"
            value={selectedBrandId}
            options={brands.map(brand => ({ value: brand.id.toString(), label: brand.name }))}
            onChange={(e) => setSelectedBrandId(e.target.value)}
            className="mb-4"
          />
          <InputField
            label="Car Category"
            type="select"
            name="category"
            value={selectedCategory}
            options={carCategories}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="mb-4"
          />
           <InputField
            label="Featured"
            type="select"
            name="isFeatured"
            options={featureOptions}
            value={isFeatured.toString()}
            onChange={(e) => setIsFeatured(e.target.value === 'true')}
            className="mb-4"
          />
          <h2 className={`text-sm font-bold mb-2 ${AdminColors.secondaryText}`} >Upload Images</h2>
          <UploadFile onFileUpload={(files) => setImages(files as File[])} multiple />
          <Button size='small' onClick={submitCarDetails} className="mt-4 bg-orange-700 hover:bg-orange-800">
            Submit to Database
          </Button>
        </div>
      </div>
    ) : (
      <div className="text-center">
        <p className="text-gray-500">Please fetch details first.</p>
      </div>
    )}
  </div>
    </AdminLayout>
  );
};

export default SellCar;
