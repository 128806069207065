import React, { useEffect, useState } from 'react';

const BACKEND_URL = 'https://eeqo.link'; // Backend URL

type SubItem = {
  name: string;
  href: string;
  imageUrl?: string;
};

type NavItem = {
  name: string;
  href?: string;
  subItems?: SubItem[];
};

interface NavBarProps {
  isHomepage?: boolean; // Add prop to specify if it's the homepage
}

const NavBar: React.FC<NavBarProps> = ({ isHomepage = false }) => {
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [navbarData, setNavbarData] = useState<Record<string, NavItem>>({
    "Home": { name: "Home", href: "/" },
    // "About Us": { name: "About Us", href: "/about-us" },
    "Stock": { name: "Stock", subItems: [] },
    "Mot": { name: "MOT", href: "/mot" },
    "Contact Us": { name: "Contact Us", href: "/contact-us" },
  });

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/api/brands/list`);
        if (!response.ok) throw new Error('Network response was not ok');
        const result = await response.json();
        const brands = result.brands;
        
        if (!Array.isArray(brands)) {
          console.error('Expected an array of brands, received:', brands);
          return;
        }
        
        setNavbarData(prev => ({
          ...prev,
          "Stock": {
            name: "Stock",
            subItems: brands.map((brand) => ({
              name: `Used ${brand.name}`,
              href: `/listing?make=${encodeURIComponent(brand.name)}`,
              imageUrl: `${BACKEND_URL}${brand.image_url}`
            }))
          }
        }));
      } catch (error) {
        console.error('Failed to fetch brands:', error);
      }
    };
  
    fetchBrands();
  }, []);

  return (
    <div className={`absolute ${isHomepage ? 'top-32' : 'top-0'} border-t border-b border-gray-500 left-0 right-0 z-20 bg-slate-800 text-white`}>
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center py-4">
          <button
            className="md:hidden block text-gray-500 focus:text-white focus:outline-none"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
          <div className={`${isMobileMenuOpen ? 'block' : 'hidden'} md:flex space-x-4`}>
            {Object.entries(navbarData).map(([key, item]) => (
              <div key={key} className="relative group" onMouseLeave={() => setActiveDropdown(null)}>
                {item.subItems && item.subItems.length > 0 ? (
                  <>
                    <button 
                      className="hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium flex items-center"
                      onMouseEnter={() => setActiveDropdown(key)}
                      onClick={() => setIsMobileMenuOpen(false)}
                    >
                      {item.name}
                    </button>
                    {activeDropdown === key && (
                      <div className="absolute left-0 w-48 shadow-lg bg-white text-black z-50"> {/* Adjusted z-index here */}
                        {item.subItems.map((subItem, index) => (
                          <a key={index} href={subItem.href} className="block px-4 py-2 text-sm hover:bg-gray-100 flex items-center">
                            {subItem.imageUrl && <img src={subItem.imageUrl} alt={subItem.name} className="w-8 h-8 object-contain mr-2" />}
                            {subItem.name}
                          </a>
                        ))}
                      </div>
                    )}
                  </>
                ) : (
                  <a key={item.href} href={item.href} className="hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium flex items-center" onClick={() => setIsMobileMenuOpen(false)}>
                    {item.name}
                  </a>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
