import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AdminColors from '../../components/admin-panel/AdminColors';
import AdminTable from '../../components/admin-panel/Table';
import Card from '../../components/admin-panel/Card';
import InputField from '../../components/admin-panel/InputField';
import Button from '../../components/admin-panel/Button';
import Notification from '../../components/admin-panel/Notification';
import NavBar from '../../components/web/NavBar';
import Footer from '../../components/web/Footer';
import NewsletterSubscription from '../../components/web/NewsletterSubscription';
import { FaCar, FaPalette, FaCalendarAlt, FaTag, FaCheckCircle, FaUser, FaEnvelope, FaPhone, FaComment } from 'react-icons/fa';
import HeroImage from '../../images/hero-image.jpg'
import HeroSection from '../../components/web/HeroSection';
import FinanceForm from '../../components/admin-panel/FinanceForm';

interface Car {
  id: number;
  registration_number: string;
  make: string;
  year: number;
  color: string;
  fuelType: string;
  taxStatus: string;
  engineCapacity: string;
  co2Emissions: string;
  price: number;
  mileage: number;
  category: string;
  status: string;
  description?: string;
  images: string[];
}

interface FinanceFormData {
  title: string;
  firstname: string;
  surname: string;
  maritalStatus: string;
  drivingLicenseNo: string;
  noOfDependents: string;
  mobile: string;
  landline: string;
  email: string;
}


// Define the notification state with a strict type
interface NotificationState {
  show: boolean;
  message: string;
  type: 'success' | 'error';
}

const FinancePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [car, setCar] = useState<Car | null>(null);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState<NotificationState>({ show: false, message: '', type: 'error' });
  const [error, setError] = useState('');
  const BACKEND_URL = 'https://eeqo.link';

  const handleFinanceSubmit = (formData: FinanceFormData & { car_id: number }) => {
    console.log('Finance Form Data:', formData);

    const formattedData = {
      car_id: formData.car_id,
      title: formData.title,
      firstname: formData.firstname,
      surname: formData.surname,
      marital_status: formData.maritalStatus, // Convert to match database column name
      driving_license_no: formData.drivingLicenseNo, // Convert to match database column name
      no_of_dependents: formData.noOfDependents, // Convert to match database column name
      mobile: formData.mobile,
      landline: formData.landline,
      email: formData.email,
    };

    // Example: Post form data to an API
    fetch(`${BACKEND_URL}/api/finance`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formattedData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Finance application submitted:', data);
        setNotification({ show: true, message: 'Application submitted successfully!', type: 'success' });
      })
      .catch((err) => {
        console.error('Error submitting finance application:', err);
        setNotification({ show: true, message: 'Failed to submit application.', type: 'error' });
      });
  };


  useEffect(() => {
    fetchCarDetails();
  }, [id]);

  const fetchCarDetails = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await fetch(`${BACKEND_URL}/api/sell-cars/details/${id}`);
      if (response.ok) {
        const data = await response.json();
        setCar({
          ...data.car,
          fuelType: data.car.fuel_type,
          isFeatured: data.car.isFeatured ?? false,
          taxStatus: data.car.tax_status,
          engineCapacity: data.car.engine_capacity,
        });
      
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to fetch car details.');
      }
    } catch (err) {
      setError('An error occurred while fetching car details.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <p className="text-blue-500 mt-4">Loading car details...</p>;
  if (error) return <p className="text-red-500 mt-4">{error}</p>;
  if (!car) return <p className="text-red-500 mt-4">No car details available.</p>;

  const sliderSettings = {
    dots: true,
    infinite: car.images.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

return (
<div className='bg-gray-900'>
  <NavBar />
    <HeroSection 
        height="h-120"
        backgroundImage={HeroImage}
        title="Secure Your Car Financing"
        subTitle="Get affordable financing options for the car of your dreams."
    />
  {loading && <p className="text-gray-500 mt-4">Loading car details...</p>}
  {!loading && car && (
    <div className="container mx-auto px-4 pt-12">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
        {/* Car Images */}
        <div className="md:col-span-2">
          <h1 className="text-2xl text-gray-300 font-bold mb-4">{car.make || 'Unknown Make'} Pictures</h1>
          {car.images && car.images.length > 0 ? (
            <Slider {...sliderSettings}>
              {car.images.map((image, index) => (
                <div key={index} className="h-96 flex justify-center items-center">
                  <img
                    src={`${BACKEND_URL}${image}`}
                    alt={`Car Image ${index + 1}`}
                    className="w-full h-full object-cover rounded"
                  />
                </div>
              ))}
            </Slider>
          ) : (
            <p className={`text-sm ${AdminColors.secondaryText}`}>
              No images available for this car.
            </p>
          )}
        </div>

        {/* Basic Car Details */}
        <div className="md:col-span-3">
          <h1 className="text-2xl text-gray-300 font-bold mb-4">Basic Car Details</h1>
          <AdminTable
            columns={[
              { key: 'field', header: 'Field' },
              { key: 'value', header: 'Value' },
            ]}
            data={[
              { 
                field: (
                  <div className="flex items-center">
                    <FaCar className="mr-3 text-2xl text-slate-300" />
                    Registration Number
                  </div>
                ),
                value: car.registration_number 
              },
              { 
                field: (
                  <div className="flex items-center">
                    <FaTag className="mr-3 text-2xl text-slate-300" />
                    Make
                  </div>
                ),
                value: car.make 
              },
              { 
                field: (
                  <div className="flex items-center">
                    <FaCalendarAlt className="mr-3 text-2xl text-slate-300" />
                    Year
                  </div>
                ),
                value: car.year 
              },
              { 
                field: (
                  <div className="flex items-center">
                    <FaPalette className="mr-3 text-2xl text-slate-300" />
                    Color
                  </div>
                ),
                value: car.color 
              },
              { 
                field: (
                  <div className="flex items-center">
                    <FaCheckCircle className="mr-3 text-2xl text-slate-300" />
                    Status
                  </div>
                ),
                value: car.status 
              },
            ]}
            headerBgColor="secondary"
            tableBgColor="tertiary"
            borderColor="border"
            textColor="primaryText"
            className="mb-4"
          />
        </div>
      </div>

        <div className="mb-8">
        <h2 className='text-lg text-gray-300 mb-4 font-bold'>Finance This Car</h2>
              {/* Display car details here */}
              <FinanceForm carId={car.id} onSubmit={handleFinanceSubmit} />
          <div className='mt-8'>
            {notification.show && <Notification message={notification.message} type={notification.type} />}
          </div>
        </div>
      </div>
    )}
<NewsletterSubscription />
<Footer />
</div>
);

};

export default FinancePage;
