import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/AuthContext';
import AdminLayout from '../../../components/admin-panel/AdminLayout';
import { FaCar, FaTools, FaDollarSign, FaEnvelopeOpenText, FaRoad, FaHandshake, FaBan,FaHourglassHalf, FaPhone, FaCheckCircle } from 'react-icons/fa'; // Example icons
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  plugins: {
    legend: {
      display: true,
    },
    title: {
      display: true,
      text: 'Overview of Operations',
    },
  },
};


interface DashboardStats {
  totalCars: number;
  totalMOTBookings: number;
  totalFinanceApplications: number;
  totalInquiries: number;
  totalTestDrives: number;
  soldCars: number;
  unsoldCars: number;
  pendingTestDrives: number,
  contactedTestDrives: number,
  completedTestDrives: number,
  pendingMot: number,
  contactedMot: number,
  completedMot: number,
  pendingFinance: number,
  approvedFinance: number,
  rejectedFinance: number
}

const Dashboard: React.FC = () => {
  const { user } = useAuth();
  const [stats, setStats] = useState<DashboardStats>({
    totalCars: 0,
    totalMOTBookings: 0,
    totalFinanceApplications: 0,
    totalInquiries: 0,
    totalTestDrives: 0,
    soldCars: 0,
    unsoldCars: 0,
    pendingTestDrives: 0,
    contactedTestDrives: 0,
    completedTestDrives: 0,
    pendingMot: 0,
    contactedMot: 0,
    completedMot: 0,
    pendingFinance: 0,
    approvedFinance: 0,
    rejectedFinance: 0
  });

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await fetch('https://eeqo.link/api/dashboard/stats');
        if (response.ok) {
          const data: DashboardStats = await response.json();
          setStats(data);
        } else {
          console.error('Failed to fetch dashboard stats');
        }
      } catch (error) {
        console.error('Error fetching dashboard stats:', error);
      }
    };

    fetchStats();
  }, []);

  const data = {
    labels: ['Total Cars', 'MOT Bookings', 'Finance Applications', 'Inquiries', 'Test Drives', 'Sold Cars', 'Unsold Cars'],
    datasets: [
      {
        label: 'Total Count',
        data: [stats.totalCars, stats.totalMOTBookings, stats.totalFinanceApplications, stats.totalInquiries, stats.totalTestDrives, stats.soldCars, stats.unsoldCars],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(22, 160, 133, 0.2)',
          'rgba(192, 57, 43, 0.2)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(22, 160, 133, 1)',
          'rgba(192, 57, 43, 1)' 
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <AdminLayout>
      <h1 className="text-lg text-slate-300">Welcome to the Dashboard, {user ? user.name : 'Guest'}</h1>
      <div className="relative h-[300px] md:h-[400px] lg:h-[500px] bg-slate-800 rounded-lg shadow-md flex items-center justify-center p-4 mt-2"> 
        <Bar data={data} options={options} />
      </div>
      <h1 className="text-lg text-slate-300 px-6 mt-8">Test Drive Queries</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-2 px-6">
        <div className="flex items-center bg-slate-700 p-4 rounded shadow">
            <FaHourglassHalf className="text-2xl mr-2" />
            <div className='ml-3'>
              <p className="text-sm">Pending</p>
              <p className="text-2xl font-bold italic">{stats.pendingTestDrives}</p>
            </div>
          </div>
          <div className="flex items-center bg-indigo-600 p-4 rounded shadow">
            <FaPhone className="text-2xl mr-2" />
            <div className='ml-3'>
              <p className="text-sm">Contacted</p>
              <p className="text-2xl font-bold italic">{stats.contactedTestDrives}</p>
            </div>
          </div>
          <div className="flex items-center bg-emerald-700 p-4 rounded shadow">
            <FaCheckCircle className="text-2xl mr-2" />
            <div className='ml-3'>
              <p className="text-sm">Completed</p>
              <p className="text-2xl font-bold italic">{stats.completedTestDrives}</p>
            </div>
          </div>
        </div>

        <h1 className="text-lg text-slate-300 px-6 mt-8">MOT Queries</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-2 px-6">
          <div className="flex items-center bg-slate-700 p-4 rounded shadow">
              <FaHourglassHalf className="text-2xl mr-2" />
              <div className='ml-3'>
                <p className="text-sm">Pending</p>
                <p className="text-2xl font-bold italic">{stats.pendingMot}</p>
              </div>
            </div>
            <div className="flex items-center bg-indigo-600 p-4 rounded shadow">
              <FaPhone className="text-2xl mr-2" />
              <div className='ml-3'>
                <p className="text-sm">Contacted</p>
                <p className="text-2xl font-bold italic">{stats.contactedMot}</p>
              </div>
            </div>
            <div className="flex items-center bg-emerald-700 p-4 rounded shadow">
              <FaCheckCircle className="text-2xl mr-2" />
              <div className='ml-3'>
                <p className="text-sm">Completed</p>
                <p className="text-2xl font-bold italic">{stats.completedMot}</p>
              </div>
            </div>
          </div>

        <h1 className="text-lg text-slate-300 px-6 mt-8">Finance Applications</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-2 px-6 mb-10">
          <div className="flex items-center bg-slate-700 p-4 rounded shadow">
              <FaHourglassHalf className="text-2xl mr-2" />
              <div className='ml-3'>
                <p className="text-sm">Pending</p>
                <p className="text-2xl font-bold italic">{stats.pendingFinance}</p>
              </div>
            </div>
            <div className="flex items-center bg-red-700 p-4 rounded shadow">
              <FaBan className="text-2xl mr-2" />
              <div className='ml-3'>
                <p className="text-sm">Rejected </p>
                <p className="text-2xl font-bold italic">{stats.rejectedFinance}</p>
              </div>
            </div>
            <div className="flex items-center bg-emerald-700 p-4 rounded shadow">
              <FaCheckCircle className="text-2xl mr-2" />
              <div className='ml-3'>
                <p className="text-sm">Approved</p>
                <p className="text-2xl font-bold italic">{stats.approvedFinance}</p>
              </div>
            </div>
          </div>
    </AdminLayout>
  );
};

export default Dashboard;
