import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // AOS styles
import AboutImage from "../../images/hero-image.jpg";

const HomeAbout = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Whether animation should happen only once
    });
  }, []);

  return (
    <div className="home-about-section bg-gray-900 text-white py-16 px-6 lg:px-16">
      <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-10 items-center">
        {/* Image Section */}
        <div
          className="image-section"
          data-aos="fade-right" // AOS animation
        >
          <img
            src={AboutImage}
            alt="About Us"
            className="w-full h-auto rounded-lg shadow-lg"
          />
        </div>
        {/* Text Section */}
        <div
          className="text-section"
          data-aos="fade-left" // AOS animation
        >
          <h2 className="text-3xl text-gray-300 font-bold mb-4">About Us</h2>
          <h3 className="text-xl text-orange-700 font-semibold mb-2">
            Who We Are
          </h3>
          <p className="text-gray-400 mb-6">
            We are passionate about delivering exceptional services and products
            to our clients. With years of expertise, we strive to innovate and
            excel in every project we undertake.
          </p>
          <ul className="list-disc text-gray-300 pl-5 space-y-2">
            <li>High-quality products and services</li>
            <li>Customer-focused solutions</li>
            <li>Innovative and cutting-edge designs</li>
            <li>Commitment to excellence and sustainability</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HomeAbout;
