import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/AuthContext';
import AdminLayout from '../../../components/admin-panel/AdminLayout';

interface TestDrive {
  id: number;
  car_id: number;
  firstname: string;
  lastname: string;
  email: string;
  telephone: number;
  preferred_date: string;
  preferred_time: string;
  message: string;
  status: string;
}

const TestDriveQueries: React.FC = () => {
  const { user } = useAuth();
  const [testDrives, setTestDrives] = useState<TestDrive[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  useEffect(() => {
    const fetchTestDrives = async () => {
      try {
        const response = await fetch('https://eeqo.link/api/test-drives/');
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        setTestDrives(data);
      } catch (error) {
        console.error('Error fetching test drives:', error);
      }
    };

    fetchTestDrives();
  }, []);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentTestDrives = testDrives.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(testDrives.length / recordsPerPage);

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const updateStatus = async (id: number, newStatus: string) => {
    try {
      const response = await fetch(`https://eeqo.link/api/test-drives/${id}/status`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: newStatus })
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      
      const updatedDrive = await response.json();
      setTestDrives(testDrives.map(td => td.id === id ? { ...td, status: updatedDrive.testDrive.status } : td));
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  return (
    <AdminLayout>
      <h1 className="text-2xl font-bold">Test Drive Queries</h1>
      <div className="mt-4">
        {currentTestDrives.length > 0 ? (
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="py-3 px-6">Vehicle Id</th>
                <th scope="col" className="py-3 px-6">Full Name</th>
                <th scope="col" className="py-3 px-6">Email</th>
                <th scope="col" className="py-3 px-6">Phone</th>
                <th scope="col" className="py-3 px-6">Preferred Date & Time</th>
                <th scope="col" className="py-3 px-6">Message</th>
                <th scope="col" className="py-3 px-6">Status</th>
                <th scope="col" className="py-3 px-6">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentTestDrives.map((drive) => (
                <tr key={drive.id} className="bg-white border-b dark:bg-slate-800 dark:border-gray-700">
                  <td className="py-4 px-6">
                      <a
                        href={`/sell-car/details/${drive.car_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-orange-500 cursor-pointer"
                      >
                        {drive.car_id}
                      </a>
                  </td>
                  <td className="py-4 px-6">{drive.firstname} {drive.lastname}</td>
                  <td className="py-4 px-6">{drive.email}</td>
                  <td className="py-4 px-6">{drive.telephone}</td>
                  <td className="py-4 px-6">{new Date(drive.preferred_date).toLocaleDateString()} - {drive.preferred_time.slice(0, 5)}</td>
                  <td className="py-4 px-6">{drive.message}</td>
                  <td className="py-4 px-6">{drive.status}</td>
                  <td className="py-4 px-6">
                    <button 
                      onClick={() => updateStatus(drive.id, 'contacted')} 
                      className="text-indigo-500 hover:text-indigo-900 px-3 py-1"
                      disabled={drive.status === 'completed'}
                    >
                      Contacted
                    </button>
                    <button 
                      onClick={() => updateStatus(drive.id, 'completed')} 
                      className="text-green-600 hover:text-green-900 px-3 py-1"
                      disabled={drive.status === 'completed'}
                    >
                      Completed
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No test drive requests found.</p>
        )}
      </div>
      <div className="flex items-center justify-center space-x-2 mt-4">
              <button 
                onClick={() => handleChangePage(1)} 
                className={`px-3 py-1 text-sm text-white bg-slate-700 rounded hover:bg-orange-700 ${currentPage === 1 && 'opacity-50 cursor-not-allowed'}`} 
                disabled={currentPage === 1}
              >
                First
              </button>
              <button 
                onClick={() => handleChangePage(currentPage - 1)} 
                className={`px-3 py-1 text-sm text-white bg-slate-700 rounded hover:bg-orange-700 ${currentPage === 1 && 'opacity-50 cursor-not-allowed'}`} 
                disabled={currentPage === 1}
              >
                Prev
              </button>
              <button 
                onClick={() => handleChangePage(currentPage + 1)} 
                className={`px-3 py-1 text-sm text-white bg-slate-700 rounded hover:bg-orange-700 ${currentPage === totalPages && 'opacity-50 cursor-not-allowed'}`} 
                disabled={currentPage === totalPages}
              >
                Next
              </button>
              <button 
                onClick={() => handleChangePage(totalPages)} 
                className={`px-3 py-1 text-sm text-white bg-slate-700 rounded hover:bg-orange-700 ${currentPage === totalPages && 'opacity-50 cursor-not-allowed'}`} 
                disabled={currentPage === totalPages}
              >
                Last
              </button>
            </div>
    </AdminLayout>
  );
};

export default TestDriveQueries;
