import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaList } from 'react-icons/fa';
import MotLogo from '../../images/mot-logo.png'

type HeroSectionProps = {
  height: string;
  backgroundImage: string;
  title: string;
  subTitle: string;
  buttonText?: string;
  motButtonText?: string;
};

const HeroSection: React.FC<HeroSectionProps> = ({
  height,
  backgroundImage,
  title,
  subTitle,
  buttonText,
  motButtonText,
}) => {
  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <div className={`relative ${height} flex items-center justify-center text-center overflow-hidden`}>
      <img
        src={backgroundImage}
        alt="Elegant Background"
        className="absolute inset-0 w-full h-full object-cover"
      />

      <div className="absolute inset-0 bg-black opacity-50"></div>

      <div className="z-10 text-white p-4" style={{ marginTop: '8rem' }}>
        <h1 className="text-4xl font-bold mb-4" data-aos="fade-up">
          {title}
        </h1>
        <p className="mb-6" data-aos="fade-up" data-aos-delay="300">
          {subTitle}
        </p>

        {buttonText && (
          <button
            className="bg-orange-700 hover:bg-orange-800 text-white font-bold italic py-3 px-12 rounded outline outline-1 outline-gray-100 shadow-md transition-all duration-300 ease-in-out flex items-center justify-center mx-auto mb-4" // Added mb-4 for spacing
            data-aos="fade-up"
            data-aos-delay="600"
          >
            <a href="/listing" className="flex items-center justify-center w-full h-full">
              <FaList className="mr-2"/>
              {buttonText}
            </a>
          </button>
        )}
        {motButtonText && (
          <button
            className="bg-blue-700 hover:bg-blue-800 text-white font-bold italic py-3 px-12 rounded outline outline-1 outline-gray-100 shadow-md transition-all duration-300 ease-in-out flex items-center justify-center mx-auto"
            data-aos="fade-up"
            data-aos-delay="900"
          >
            <a href="/mot" className="flex items-center justify-center w-full h-full">
              <img src={MotLogo} alt="MOT Logo" className="mr-2 h-6 w-auto"/>
              {motButtonText}
            </a>
          </button>
        )}
      </div>
    </div>
  );
};

export default HeroSection;
