import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AdminColors from '../../components/admin-panel/AdminColors';
import AdminTable from '../../components/admin-panel/Table';
import Card from '../../components/admin-panel/Card';
import InputField from '../../components/admin-panel/InputField';
import Button from '../../components/admin-panel/Button';
import Notification from '../../components/admin-panel/Notification';
import NavBar from '../../components/web/NavBar';
import Footer from '../../components/web/Footer';
import NewsletterSubscription from '../../components/web/NewsletterSubscription';
import { FaCar, FaPalette, FaCalendarAlt, FaTag, FaCheckCircle, FaUser, FaEnvelope, FaPhone, FaComment, FaClock } from 'react-icons/fa';
import HeroImage from '../../images/hero-image.jpg'
import HeroSection from '../../components/web/HeroSection';

interface Car {
  id: number;
  registration_number: string;
  make: string;
  year: number;
  color: string;
  fuelType: string;
  taxStatus: string;
  engineCapacity: string;
  co2Emissions: string;
  price: number;
  mileage: number;
  category: string;
  status: string;
  description?: string;
  images: string[];
}

// Define the notification state with a strict type
interface NotificationState {
  show: boolean;
  message: string;
  type: 'success' | 'error';
}

interface Inquiry {
    firstname: string;
    lastname: string;
    email: string;
    telephone: string;
    preferredDate: string;
    preferredTime: string;
    message: string;
  }  

const TestDrive: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [car, setCar] = useState<Car | null>(null);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState<NotificationState>({ show: false, message: '', type: 'error' });
  const [error, setError] = useState('');
  const BACKEND_URL = 'https://eeqo.link';

  // Form state
  const [inquiry, setInquiry] = useState<Inquiry>({
    firstname: '',
    lastname: '',
    email: '',
    telephone: '',
    preferredDate: '',
    preferredTime: '',
    message: ''
  });
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setInquiry((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    fetchCarDetails();
  }, [id]);

  const fetchCarDetails = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await fetch(`${BACKEND_URL}/api/sell-cars/details/${id}`);
      if (response.ok) {
        const data = await response.json();
        setCar({
          ...data.car,
          fuelType: data.car.fuel_type,
          isFeatured: data.car.isFeatured ?? false,
          taxStatus: data.car.tax_status,
          engineCapacity: data.car.engine_capacity,
        });
      
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to fetch car details.');
      }
    } catch (err) {
      setError('An error occurred while fetching car details.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle the test drive request submission
  const handleTestDriveRequest = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/api/test-drives`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...inquiry, carId: id }),
      });

      if (response.ok) {
        setNotification({ show: true, message: 'Test drive scheduled successfully!', type: 'success' });
      } else {
        const errorData = await response.json();
        setNotification({ show: true, message: errorData.message || 'Failed to schedule test drive.', type: 'error' });
      }
    } catch (error) {
      console.error('Error scheduling test drive:', error);
      setNotification({ show: true, message: 'An error occurred. Please try again later.', type: 'error' });
    }
  };

  if (loading) return <p className="text-blue-500 mt-4">Loading car details...</p>;
  if (error) return <p className="text-red-500 mt-4">{error}</p>;
  if (!car) return <p className="text-red-500 mt-4">No car details available.</p>;

  const sliderSettings = {
    dots: true,
    infinite: car.images.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

return (
<div className='bg-gray-900'>
  <NavBar />
    <HeroSection 
        height="h-120"
        backgroundImage={HeroImage}
        title="Test Drive"
        subTitle="Get affordable financing options for the car of your dreams."
    />
  {loading && <p className="text-gray-500 mt-4">Loading car details...</p>}
  {!loading && car && (
    <div className="container mx-auto px-4 pt-12">
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mb-8">
        {/* Car Images */}
        <div className="md:col-span-2">
          <h1 className="text-2xl text-gray-300 font-bold mb-4">{car.make || 'Unknown Make'} Pictures</h1>
          {car.images && car.images.length > 0 ? (
            <Slider {...sliderSettings}>
              {car.images.map((image, index) => (
                <div key={index} className="h-96 flex justify-center items-center">
                  <img
                    src={`${BACKEND_URL}${image}`}
                    alt={`Car Image ${index + 1}`}
                    className="w-full h-full object-cover rounded"
                  />
                </div>
              ))}
            </Slider>
          ) : (
            <p className={`text-sm ${AdminColors.secondaryText}`}>
              No images available for this car.
            </p>
          )}
        </div>

        {/* Basic Car Details */}
        <div className="md:col-span-3">
          <h1 className="text-2xl text-gray-300 font-bold mb-4">Basic Car Details</h1>
          <AdminTable
            columns={[
              { key: 'field', header: 'Field' },
              { key: 'value', header: 'Value' },
            ]}
            data={[
              { 
                field: (
                  <div className="flex items-center">
                    <FaCar className="mr-3 text-2xl text-slate-300" />
                    Registration Number
                  </div>
                ),
                value: car.registration_number 
              },
              { 
                field: (
                  <div className="flex items-center">
                    <FaTag className="mr-3 text-2xl text-slate-300" />
                    Make
                  </div>
                ),
                value: car.make 
              },
              { 
                field: (
                  <div className="flex items-center">
                    <FaCalendarAlt className="mr-3 text-2xl text-slate-300" />
                    Year
                  </div>
                ),
                value: car.year 
              },
              { 
                field: (
                  <div className="flex items-center">
                    <FaPalette className="mr-3 text-2xl text-slate-300" />
                    Color
                  </div>
                ),
                value: car.color 
              },
              { 
                field: (
                  <div className="flex items-center">
                    <FaCheckCircle className="mr-3 text-2xl text-slate-300" />
                    Status
                  </div>
                ),
                value: car.status 
              },
            ]}
            headerBgColor="secondary"
            tableBgColor="tertiary"
            borderColor="border"
            textColor="primaryText"
            className="mb-4"
          />
        </div>
      </div>

      <div className="mb-8">
  <h2 className='text-lg text-gray-300 mb-4 font-bold'>Schedule a Test Drive</h2>
  <div className='bg-slate-700 px-6 py-8 rounded-lg'>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div className="flex items-center">
        <FaUser className="mr-3 text-xl text-slate-300" />
        <input
          type="text"
          name="firstname"
          placeholder="Firstname"
          value={inquiry.firstname}
          onChange={handleInputChange}
          className="w-full p-2 border border-slate-500 text-sm bg-slate-800 text-slate-300 rounded"
          required
        />
      </div>
      <div className="flex items-center">
        <FaUser className="mr-3 text-xl text-slate-300" />
        <input
          type="text"
          name="lastname"
          placeholder="Lastname"
          value={inquiry.lastname}
          onChange={handleInputChange}
          className="w-full p-2 border border-slate-500 text-sm bg-slate-800 text-slate-300 rounded"
          required
        />
      </div>
      <div className="flex items-center">
        <FaEnvelope className="mr-3 text-xl text-slate-300" />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={inquiry.email}
          onChange={handleInputChange}
          className="w-full p-2 border border-slate-500 text-sm bg-slate-800 text-slate-300 rounded"
          required
        />
      </div>
      <div className="flex items-center">
        <FaPhone className="mr-3 text-xl text-slate-300" />
        <input
          type="text"
          name="telephone"
          placeholder="Telephone"
          value={inquiry.telephone}
          onChange={handleInputChange}
          className="w-full p-2 border border-slate-500 text-sm bg-slate-800 text-slate-300 rounded"
          required
        />
      </div>
      <div className="flex items-center">
        <FaCalendarAlt className="mr-3 text-xl text-slate-300" />
        <input
          type="date"
          name="preferredDate"
          value={inquiry.preferredDate}
          onChange={handleInputChange}
          className="w-full p-2 border border-slate-500 text-sm bg-slate-800 text-slate-300 rounded"
          required
        />
      </div>
      <div className="flex items-center">
        <FaClock className="mr-3 text-xl text-slate-300" />
        <input
          type="time"
          name="preferredTime"
          value={inquiry.preferredTime}
          onChange={handleInputChange}
          className="w-full p-2 border border-slate-500 text-sm bg-slate-800 text-slate-300 rounded"
          required
        />
      </div>
      <div className="flex items-center">
        <FaComment className="mr-3 text-xl text-slate-300" />
        <textarea
          name="message"
          placeholder="Message (Optional)"
          value={inquiry.message}
          onChange={handleInputChange}
          className="w-full p-2 border border-slate-500 text-sm bg-slate-800 text-slate-300 rounded"
          rows={4}
        ></textarea>
      </div>
    </div>
    <Button size="small" onClick={handleTestDriveRequest} className="mt-4">
      Schedule Test Drive
    </Button>

    <div className='mt-8'>
            {notification.show && <Notification message={notification.message} type={notification.type} />}
          </div>

  </div>
</div>

      </div>
    )}
<NewsletterSubscription />
<Footer />
</div>
);

};

export default TestDrive;
