import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white pt-8">
      <div className="container mx-auto px-6 lg:px-16 grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Quick Links */}
        <div>
          <h5 className="text-lg text-gray-300 font-bold mb-3">Quick Links</h5>
          <ul className="space-y-2 text-gray-400">
            <li><a href="/home" className="hover:text-orange-500 transition-colors">Home</a></li>
            <li><a href="/about" className="hover:text-orange-500 transition-colors">About Us</a></li>
            <li><a href="/services" className="hover:text-orange-500 transition-colors">Services</a></li>
            <li><a href="/contact" className="hover:text-orange-500 transition-colors">Contact</a></li>
          </ul>
        </div>

        {/* Social Media Links */}
        <div>
          <h5 className="text-lg text-gray-300 font-bold mb-3">Follow Us</h5>
          <div className="flex space-x-4 text-gray-400">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-600"><FaFacebook size={20} /></a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-300"><FaTwitter size={20} /></a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="hover:text-pink-500"><FaInstagram size={20} /></a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-500"><FaLinkedin size={20} /></a>
          </div>
        </div>

        {/* Legal Information */}
        <div>
          <h5 className="text-lg text-gray-300 font-bold mb-3">Legal</h5>
          <ul className="space-y-2 text-gray-400">
            <li><a href="/terms" className="hover:text-orange-500 transition-colors">Terms of Service</a></li>
            <li><a href="/privacy" className="hover:text-orange-500 transition-colors">Privacy Policy</a></li>
          </ul>
        </div>
      </div>

      {/* Copyright */}
      <div className="text-center text-gray-400 mt-8 text-sm bg-slate-800 py-4">
        © {new Date().getFullYear()} <span className='text-orange-700'>Dragon Autos</span>. All Rights Reserved. Powered by <a href="https://vravenz.com" className='text-gray-100'>vravenz</a>
      </div>
    </footer>
  );
};

export default Footer;
