import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import logo from '../../images/logo.png';
import { FiPhone } from 'react-icons/fi';
import { MdEmail } from 'react-icons/md';

const HeaderSection = () => {
  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true, // Animation should only run once
    });
  }, []);

  return (
    <div className="absolute top-0 left-0 right-0 z-30 h-32 flex items-center bg-transparent">
      <div className="absolute inset-0 bg-black bg-opacity-20"></div>

      <div className="container mx-auto flex justify-between items-center p-4 text-white text-sm">
        {/* Logo with AOS animation */}
        <img
          src={logo}
          alt="Logo"
          className="h-20"
          data-aos="fade-right"
        />

        {/* Contact Information with AOS animation */}
        <div className="flex flex-col items-start" data-aos="fade-left">
          {/* Phone Number */}
          <div className="flex items-center space-x-2">
            <FiPhone className="text-lg" />
            <span className="text-lg font-bold italic">+123 456 7890</span>
          </div>

          {/* Email */}
          <div className="flex items-center space-x-2">
            <MdEmail className="text-lg" />
            <span>email@example.com</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderSection;
