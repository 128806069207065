import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Define a type for partner data to match the database schema
interface Partner {
  id: number;
  name: string;
  image_url: string;  // Use the same property name as in the database
}

const BACKEND_URL = 'https://eeqo.link'; // Ensure this URL is correct

const OurPartners = () => {
  const [partners, setPartners] = useState<Partner[]>([]);

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/api/brands/list`);
        if (!response.ok) {
          throw new Error('Failed to fetch partners');
        }
        const data = await response.json();
        if (!Array.isArray(data.brands)) {
          console.error('Expected an array of brands, received:', data.brands);
          return;
        }

        // Map over the brands and transform them into Partner objects
        const transformedPartners = data.brands.map((brand: any) => {
          const imageUrl = `${BACKEND_URL}${brand.image_url}`;
          return {
            id: brand.id,
            name: brand.name,
            image_url: imageUrl
          };
        });
        setPartners(transformedPartners);
      } catch (error) {
        console.error('Error fetching partners:', error);
      }
    };

    fetchPartners();
  }, []);

  const settings = {
    dots: false,
    infinite: partners.length > 4,
    speed: 500,
    slidesToShow: Math.min(partners.length, 4),
    slidesToScroll: 1,
    autoplay: partners.length > 4,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(partners.length, 3),
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(partners.length, 2),
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="our-partners-section bg-gray-900 py-16 px-6 lg:px-16">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold text-gray-300 mb-4">Our Partners</h2>
        <p className="text-gray-400 mb-10">
          Trusted collaborations with industry-leading brands.
        </p>
        {partners.length > 0 ? (
          <Slider {...settings}>
            {partners.map((partner, index) => (
              <div key={index} className="p-4">
                <div className="flex items-center justify-center h-20">
                  <img
                    src={partner.image_url} // Confirm this URL is what you expect
                    alt={partner.name}
                    className="max-w-full max-h-full"
                  />
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          <div>No partners available.</div>
        )}
      </div>
    </div>
  );
};

export default OurPartners;
