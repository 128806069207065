import React, { useState } from 'react';
import NavBar from '../../components/web/NavBar';
import HeroSection from '../../components/web/HeroSection';
import HeroImage from '../../images/contact.jpg';
import Footer from '../../components/web/Footer';
import NewsletterSubscription from '../../components/web/NewsletterSubscription';
import InputField from '../../components/admin-panel/InputField';

const Contact: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    
    try {
      const response = await fetch('https://eeqo.link/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();
      if (response.ok) {
        alert('Message sent successfully!');
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: '',
        });
      } else {
        throw new Error(data.message || 'Failed to send the message');
      }
    } catch (error: any) {
      console.error('Sending message failed:', error);
      alert(error.message);
    }
  };

  return (
    <div className="relative bg-gray-900">
      <NavBar />
      <HeroSection
        height="h-120"
        backgroundImage={HeroImage}
        title="Contact Us"
        subTitle="We’d love to hear from you! Reach out to us for any inquiries or support."
      />

      <div className="container mx-auto px-6 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-start">
          <div className="bg-slate-800 shadow-lg rounded-lg p-8">
            <h2 className="text-xl font-bold mb-6 text-slate-300">Get in Touch</h2>
            <form onSubmit={handleSubmit}>
              <InputField
                type="text"
                name="name"
                value={formData.name}
                placeholder="Enter your full name"
                onChange={handleInputChange}
                required
              />
              <InputField
                type="email"
                name="email"
                value={formData.email}
                placeholder="Enter your email"
                onChange={handleInputChange}
                required
              />
              <InputField
                type="text"
                name="subject"
                value={formData.subject}
                placeholder="Enter the subject"
                onChange={handleInputChange}
              />
              <InputField
                type="textarea"
                name="message"
                value={formData.message}
                placeholder="Enter your message"
                onChange={handleInputChange}
                required
              />
              <button
                type="submit"
                className="w-full mt-4 py-2 px-4 bg-orange-700 text-white font-bold rounded-md hover:bg-orange-800 transition"
              >
                Submit
              </button>
            </form>
          </div>

          <div className="bg-slate-800 shadow-lg rounded-lg p-8">
            <h2 className="text-xl font-bold mb-6 text-slate-300">Contact Information</h2>
            <p className="text-md mb-4 text-slate-400">
              <strong>Address:</strong> 123 Auto Dragons Street, Auto City
            </p>
            <p className="text-md mb-4 text-slate-400">
              <strong>Phone:</strong> +1 (555) 123-4567
            </p>
            <p className="text-md mb-4 text-slate-400">
              <strong>Email:</strong> contact@autodragons.com
            </p>
            <p className="text-md text-slate-400">
              <strong>Hours:</strong> Mon - Fri: 9:00 AM - 6:00 PM
            </p>
          </div>
        </div>
      </div>

      <NewsletterSubscription />
      <Footer />
    </div>
  );
};

export default Contact;
