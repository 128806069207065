import React, { useState } from 'react';
import NavBar from '../../components/web/NavBar';
import HeroSection from '../../components/web/HeroSection';
import HeroImage from '../../images/mot.jpg';
import Footer from '../../components/web/Footer';
import NewsletterSubscription from '../../components/web/NewsletterSubscription';
import Modal from '../../components/admin-panel/Modal';
import InputField from '../../components/admin-panel/InputField';
import MotServices from '../../components/web/MotServices';

const Mot: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const [selectedPackageId, setSelectedPackageId] = useState<number | null>(null);
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    date: '',
    phone: '',
    classType: 'Class 4', // Default class type
    price: '£50' // Default price for Class 4
  });

  const handleSubmit = async () => {
    const url = 'https://eeqo.link';
    const data = {
      ...formData,
      packageId: selectedPackageId
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        throw new Error('Something went wrong with the booking submission!');
      }

      const result = await response.json();
      setBookingSuccess(true);
      setFormData({ email: '', name: '', date: '', phone: '', classType: 'Class 4', price: '£50' });
      setTimeout(() => {
        setModalOpen(false); // Close the modal after showing success message
        setBookingSuccess(false); // Reset the success state for the next booking
      }, 10000);
    } catch (error) {
      console.error('Booking failed:', error);
      alert('Booking failed. Please try again.');
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleClassChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setFormData(prev => ({
      ...prev,
      classType: value,
      price: value === 'Class 4' ? '£50' : '£60'
    }));
  };

  const handlePackageSelect = (packageId: number) => {
    setSelectedPackageId(packageId);
    setModalOpen(true); // Open the modal here
    if (packageId !== 1) {  // Reset class type and price for non-MOT packages
      setFormData(prev => ({ ...prev, classType: '', price: '' }));
    }
  };

  const motPackages = [
    {
      id: 1,
      title: 'MOT',
      price: '£44.99',
      features: [
        'Suitable for most cars (up to 3 tonnes, excludes motorbikes)',
        'Free MOT reminders for peace of mind',
        'We recommend this with a Service',
      ],
      highlight: {
        text: 'FREE MOT',
        note: 'with Motoring Club premium',
      },
      buttonLabel: 'Book now',
    },
    {
      id: 2,
      title: 'Full Service',
      features: [
        '77 point, 12,000 miles inspection',
        'A more detailed maintenance checkup than an interim service',
        'Save when booking with an MOT',
      ],
      highlight: {
        text: 'From £199.49',
        note: 'with Motoring Club premium',
      },
      buttonLabel: 'Book now',
    },
  ];

  return (
    <div className="relative bg-gray-900">
      <NavBar />
      <HeroSection
        height="h-120"
        backgroundImage={HeroImage}
        title="Drive Safe, Stay Compliant"
        subTitle="Book Your MOT Today for Peace of Mind and Hassle-Free Driving"
      />

      <div className="container mx-auto mt-10">
        {/* Section Title */}
        <h2 className="text-3xl font-bold text-gray-300 mb-4 text-center">MOT Packages</h2>
        <p className="text-gray-400 mb-6 text-center">
          Choose from our tailored packages designed for your peace of mind.
        </p>

        <div className="flex justify-center items-start space-x-4 p-4 bg-gray-900">
          {motPackages.map((packageItem) => (
            <div key={packageItem.id} className="bg-slate-700 shadow-lg rounded-lg p-6 w-96">
              <h2 className="text-xl text-slate-200 font-bold mb-4">{packageItem.title}</h2>
              <p className="text-2xl font-bold text-slate-200 mb-6">
                {packageItem.price ? (
                  <>From <span className="text-green-600">{packageItem.price}</span></>
                ) : (
                  <span className="text-green-600">Contact us for pricing</span>
                )}
              </p>
              <ul className="space-y-2 mb-6 text-slate-400">
                {packageItem.features.map((feature, idx) => (
                  <li key={idx} className="flex items-center">
                    <span className="text-green-600 mr-2">✔</span> {feature}
                  </li>
                ))}
              </ul>
              <div className="flex items-center bg-yellow-200 px-3 py-2 rounded mb-4">
                <span className="bg-orange-600 text-white text-sm px-2 py-1 rounded mr-3">
                  hmc
                </span>
                <p className="text-sm">
                  <span className="font-bold">{packageItem.highlight.text}</span>{' '}
                  {packageItem.highlight.note}
                </p>
              </div>
              <button
                className="bg-orange-700 text-white py-3 w-full rounded font-bold"
                onClick={() => handlePackageSelect(packageItem.id)}
              >
                {packageItem.buttonLabel}
              </button>
            </div>
          ))}
        </div>
      </div>

      <MotServices />

      <Modal isOpen={modalOpen} title={bookingSuccess ? "Booking Successful" : `Book Your MOT - ${motPackages.find(p => p.id === selectedPackageId)?.title || ''}`} onClose={() => setModalOpen(false)}>
    {bookingSuccess ? (
      <p className="text-center text-md text-slate-200">
          Thank you for your booking request. Please note, this is not a final booking confirmation. Our team will contact you shortly to finalize the details and confirm your appointment.
      </p>
    ) : (
        <>
            <InputField
                label="Your Name"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required={true}
            />
            <InputField
                label="Email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required={true}
            />
            <InputField
                label="Phone Number"
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                required={true}
            />
            <InputField
                label="Preferred Date"
                type="date"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
                required={true}
            />
            {selectedPackageId === 1 && (
                <div className="mb-4">
                    <label className="block text-sm font-bold text-slate-200 mb-2">
                        Select Vehicle Class
                    </label>
                    <select
                        name="classType"
                        value={formData.classType}
                        onChange={handleClassChange}
                        className="block w-full bg-slate-800 border border-gray-700 text-white py-3 px-4 rounded outline-none"
                    >
                        <option value="Class 4">Class 4 - £50</option>
                        <option value="Class 7">Class 7 - £60</option>
                    </select>
                </div>
            )}
            <button
                className="mt-4 w-full bg-orange-700 text-white py-3 rounded font-bold"
                onClick={handleSubmit}
            >
                Submit Booking
            </button>
        </>
    )}
</Modal>


      <NewsletterSubscription />
      <Footer />
    </div>
  );
};

export default Mot;
