import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/AuthContext';
import AdminColors from '../../../components/admin-panel/AdminColors';
import Card from '../../../components/admin-panel/Card';
import Logo from '../../../images/logo.png'


const Login: React.FC = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const { setUser } = useAuth();
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch('https://eeqo.link/api/auth/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('auth', JSON.stringify(data.user));
        setUser(data.user);
        navigate('/dashboard');
      } else {
        const errorData = await response.json();
        alert(errorData.message || 'Login failed.');
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <div className={`flex flex-col items-center justify-top h-screen ${AdminColors.primary} ${AdminColors.primaryText}`}>
      <div className="mb-8 mt-36">
        <img src={Logo} width={170} />
      </div>
      <Card title="Welcome" subtitle="Please login to dashboard.">
        <form onSubmit={handleSubmit} className="mt-8">
          <input
            type="email"
            name="email"
            placeholder="Username"
            value={formData.email}
            onChange={handleChange}
            className={`w-full p-2 mb-4 ${AdminColors.tertiary} border-none ${AdminColors.focusBorder} focus:outline-none`}
            required
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            className={`w-full p-2 mb-4 ${AdminColors.tertiary} border-none ${AdminColors.focusBorder} focus:outline-none`}
            required
          />
          <button
            type="submit"
            className={`w-full p-3 ${AdminColors.button} border-none ${AdminColors.primaryText} cursor-pointer`}
          >
            Login
          </button>
        </form>
      </Card>
    </div>
  );
};

export default Login;
