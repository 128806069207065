import React, { useState, useEffect } from 'react';
import AdminLayout from '../../../components/admin-panel/AdminLayout';
import Modal from '../../../components/admin-panel/Modal';

interface FinanceApplication {
  id: number;
  car_id: number;
  title: string;
  firstname: string;
  surname: string;
  marital_status: string;
  driving_license_no: string;
  no_of_dependents: number;
  mobile: string;
  landline: string;
  email: string;
  status: string;
  created_at: string;
}

const FinancePage: React.FC = () => {
  const [financeApplications, setFinanceApplications] = useState<FinanceApplication[]>([]);
  const [selectedApplication, setSelectedApplication] = useState<FinanceApplication | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  useEffect(() => {
    const fetchFinanceApplications = async () => {
      try {
        const response = await fetch('https://eeqo.link/api/finance');
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        setFinanceApplications(data);
      } catch (error) {
        console.error('Error fetching finance applications:', error);
      }
    };

    fetchFinanceApplications();
  }, []);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentApplications = financeApplications.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(financeApplications.length / recordsPerPage);

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const updateStatus = async (id: number, newStatus: string) => {
    try {
      const response = await fetch(`https://eeqo.link/api/finance/${id}/status`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: newStatus }),
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
  
      const updatedApplication = await response.json();
      setFinanceApplications((prev) =>
        prev.map((app) => (app.id === id ? { ...app, status: updatedApplication.status } : app))
      );
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };
  

  const openModal = (application: FinanceApplication) => {
    setSelectedApplication(application);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <AdminLayout>
      <h1 className="text-2xl font-bold">Finance Applications</h1>
      <div className="mt-4">
        {currentApplications.length > 0 ? (
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="py-3 px-6">Vehicle Id</th>
                <th className="py-3 px-6">Full Name</th>
                <th className="py-3 px-6">Email</th>
                <th className="py-3 px-6">Mobile</th>
                <th className="py-3 px-6">Status</th>
                <th className="py-3 px-6">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentApplications.map((app) => (
                <tr key={app.id} className="bg-white border-b dark:bg-slate-800 dark:border-gray-700">
                  <td className="py-4 px-6">
                      <a
                        href={`/sell-car/details/${app.car_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-orange-500 cursor-pointer"
                      >
                        {app.car_id}
                      </a>
                  </td>
                  <td className="py-4 px-6">
                    {app.title} {app.firstname} {app.surname}
                  </td>
                  <td className="py-4 px-6">{app.email}</td>
                  <td className="py-4 px-6">{app.mobile}</td>
                  <td className="py-4 px-6">{app.status}</td>
                  <td className="py-4 px-6">
                    <button
                      onClick={() => openModal(app)}
                      className="text-indigo-400 hover:text-indigo-500 px-3 py-1"
                    >
                      View
                    </button>
                    <button
                      onClick={() => updateStatus(app.id, 'approved')}
                      className="text-green-500 hover:text-green-900 px-3 py-1"
                      disabled={app.status === 'approved' || app.status === 'rejected'}
                    >
                      Approve
                    </button>
                    <button
                      onClick={() => updateStatus(app.id, 'rejected')}
                      className="text-red-500 hover:text-red-900 px-3 py-1"
                      disabled={app.status === 'approved' || app.status === 'rejected'}
                    >
                      Reject
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No finance applications found.</p>
        )}
      </div>

      <div className="flex items-center justify-center space-x-2 mt-4">
              <button 
                onClick={() => handleChangePage(1)} 
                className={`px-3 py-1 text-sm text-white bg-slate-700 rounded hover:bg-orange-700 ${currentPage === 1 && 'opacity-50 cursor-not-allowed'}`} 
                disabled={currentPage === 1}
              >
                First
              </button>
              <button 
                onClick={() => handleChangePage(currentPage - 1)} 
                className={`px-3 py-1 text-sm text-white bg-slate-700 rounded hover:bg-orange-700 ${currentPage === 1 && 'opacity-50 cursor-not-allowed'}`} 
                disabled={currentPage === 1}
              >
                Prev
              </button>
              <button 
                onClick={() => handleChangePage(currentPage + 1)} 
                className={`px-3 py-1 text-sm text-white bg-slate-700 rounded hover:bg-orange-700 ${currentPage === totalPages && 'opacity-50 cursor-not-allowed'}`} 
                disabled={currentPage === totalPages}
              >
                Next
              </button>
              <button 
                onClick={() => handleChangePage(totalPages)} 
                className={`px-3 py-1 text-sm text-white bg-slate-700 rounded hover:bg-orange-700 ${currentPage === totalPages && 'opacity-50 cursor-not-allowed'}`} 
                disabled={currentPage === totalPages}
              >
                Last
              </button>
            </div>

      {selectedApplication && (
        <Modal isOpen={isModalOpen} onClose={closeModal} title="Client Details">
          <div>
            <p><strong>Title:</strong> {selectedApplication.title}</p>
            <p><strong>First Name:</strong> {selectedApplication.firstname}</p>
            <p><strong>Surname:</strong> {selectedApplication.surname}</p>
            <p><strong>Email:</strong> {selectedApplication.email}</p>
            <p><strong>Mobile:</strong> {selectedApplication.mobile}</p>
            <p><strong>Landline:</strong> {selectedApplication.landline}</p>
            <p><strong>Marital Status:</strong> {selectedApplication.marital_status}</p>
            <p><strong>Driving License No:</strong> {selectedApplication.driving_license_no}</p>
            <p><strong>No of Dependents:</strong> {selectedApplication.no_of_dependents}</p>
            <p><strong>Status:</strong> {selectedApplication.status}</p>
            <p><strong>Created At:</strong> {new Date(selectedApplication.created_at).toLocaleDateString()}</p>
          </div>
        </Modal>
      )}
    </AdminLayout>
  );
};

export default FinancePage;
